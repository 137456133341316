import type { UnwrapNestedRefs, UnwrapRef } from "vue"

import type { BaseSchema, InferOutput, ObjectSchema, SafeParseResult } from "valibot"
import { safeParse } from "valibot"

/**
 * Creates a Valibot field composable.
 * @param fieldName - The name of the field.
 * @param schema - The schema for the field.
 * @returns An object containing various properties and functions related to the Valibot field.
 */
export function useField<T extends BaseSchema<any, any, any>>(
  fieldName: string,
  schema: MaybeRef<T>
): UseFieldReturnType<T> {
  const schemaAsRef = toRef(schema)
  const state = ref<InferOutput<T>>(null as InferOutput<T>)
  const userErrors = ref<string[]>([])
  const dirty = ref(false)
  const touched = ref(false)
  const parseResult = ref<Pick<SafeParseResult<T>, "issues" | "success">>({
    success: false,
    issues: [] as any,
  })

  const isValid = computed((): boolean => parseResult.value.success)

  const eagerValid = computed(() => {
    const result = safeParse(schemaAsRef.value, state.value)
    return result.success
  })

  const errors = computed((): string[] => {
    return userErrors.value.concat(parseResult.value.issues?.map((issue) => issue.message as string) || [])
  })

  const errorMessage = computed((): string => {
    return errors.value?.[0]
  })

  const binds = computed(() => {
    return {
      name: fieldName,
      modelValue: state.value,
      "onUpdate:modelValue": (value: any) => {
        state.value = value
        if (!dirty.value && (value !== 0 || value !== "")) dirty.value = true

        clearErrors()
      },
      onBlur: () => {
        validate()
      },
      onFocus: () => {
        touched.value = true
      },
    }
  })

  function validate(): boolean {
    const result = safeParse(schemaAsRef.value, state.value)

    parseResult.value = {
      success: result.success,
      issues: result.issues,
    }

    return result.success
  }

  function reset(): void {
    state.value = null as InferOutput<T>
    dirty.value = false
    touched.value = false
    clearErrors()
  }

  function setErrors(...errors: string[]): void {
    userErrors.value = errors
  }

  function clearErrors(): void {
    userErrors.value = []
    parseResult.value = {
      success: false,
      issues: [] as any,
    }
  }

  return {
    state,
    dirty,
    touched,
    parseResult,
    isValid,
    eagerValid,
    errors,
    errorMessage,
    binds,
    validate,
    reset,
    setErrors,
    clearErrors,
  }
}

/**
 * Creates a form object with validation capabilities based on a given schema.
 * @param schema - The schema object used for validation.
 * @returns An object containing various form-related functions and properties.
 */
export function useForm<T extends ObjectSchema<any, any>>(schema: MaybeRef<T>): UseFormReturnType<T> {
  const schemaAsRef = toRef(schema)

  const fields = {} as Record<keyof InferOutput<T>, ReturnType<typeof useField>>

  for (const key in schemaAsRef.value.entries) {
    fields[key as keyof InferOutput<T>] = useField(key, schemaAsRef.value.entries[key])
  }

  const state = reactiveComputed((): InferOutput<T> => {
    return Object.fromEntries(
      Object.entries(fields).map(([key, field]) => [key, toRef(field.state)])
    ) as InferOutput<T>
  })

  const isValid = computed((): boolean => {
    return Object.values(fields).every((field) => field.isValid.value)
  })

  const eagerValid = computed(() => {
    return Object.values(fields).every((field) => field.eagerValid.value)
  })

  function getErrors(fieldName: keyof InferOutput<T>): string[] {
    return fields[fieldName]?.errors.value || []
  }

  function getErrorMessage(fieldName: keyof InferOutput<T>): string {
    return fields[fieldName]?.errorMessage.value
  }

  function registerField(fieldName: keyof InferOutput<T>) {
    return fields[fieldName].binds.value
  }

  function validateField(fieldName: keyof InferOutput<T>): boolean {
    return fields[fieldName]?.validate() || false
  }

  function resetField(fieldName: keyof InferOutput<T>): void {
    return fields[fieldName]?.reset()
  }

  function setFieldErrors(fieldName: keyof InferOutput<T>, ...errors: string[]): void {
    return fields[fieldName]?.setErrors(...errors)
  }

  function clearFieldErrors(fieldName: keyof InferOutput<T>): void {
    return fields[fieldName]?.clearErrors()
  }

  function validate(): boolean {
    for (const key in fields) {
      fields[key].validate()
    }

    return isValid.value
  }

  function reset(): void {
    for (const key in fields) {
      fields[key].reset()
    }
  }

  function clearErrors(): void {
    for (const key in fields) {
      fields[key]?.clearErrors()
    }
  }

  return {
    state,
    fields,
    isValid,
    eagerValid,

    registerField,
    getErrors,
    getErrorMessage,

    validate,
    reset,
    clearErrors,

    validateField,
    resetField,
    setFieldErrors,
    clearFieldErrors,
  }
}

/**
 * Represents the return type of the `useForm` composable function.
 * It provides various properties and methods related to form fields.
 *
 * @template T - The type of the form field schema.
 */
export type UseFieldReturnType<T extends BaseSchema<any, any, any>> = {
  /**
   * The state of the form field.
   */
  state: Ref<UnwrapRef<InferOutput<T>>>

  /**
   * A reactive reference to the dirty state of the form field.
   */
  dirty: Ref<boolean>

  /**
   * A reactive reference to the touched state of the form field.
   */
  touched: Ref<boolean>

  /**
   * The validation result of the form field.
   */
  parseResult: Ref<Pick<SafeParseResult<T>, "issues" | "success">>

  /**
   * A reactive reference to the validity of the form field.
   */
  isValid: ComputedRef<boolean>

  /**
   * A reactive reference to the eager validity of the form field.
   * It does not wait until the field is touched.
   */
  eagerValid: ComputedRef<boolean>

  /**
   * A reactive reference to the error message of the form field.
   */
  errorMessage: ComputedRef<string>

  /**
   * A reactive reference to the errors of the form field.
   */
  errors: ComputedRef<string[]>

  /**
   * A reactive reference to the bindings of the form field.
   */
  binds: ComputedRef<{
    name: string
    modelValue: any
    "onUpdate:modelValue": (value: any) => void
    onBlur: () => void
  }>

  /**
   * Validates the form field.
   *
   * @returns A boolean indicating whether the form field is valid.
   */
  validate: () => boolean

  /**
   * Resets the form field.
   */
  reset: () => void

  /**
   * Sets the errors of the form field.
   *
   * @param errors - The errors to set.
   */
  setErrors: (...errors: string[]) => void

  /**
   * Clears the errors of the form field.
   */
  clearErrors: () => void
}

/**
 * Represents the return type of the `useForm` composable function.
 * @template T - The type of the object schema.
 */
export type UseFormReturnType<T extends ObjectSchema<any, any>> = {
  /**
   * The reactive state object that holds the form data.
   */
  state: UnwrapNestedRefs<InferOutput<T>>

  /**
   * An object that contains field bindings for each field in the form.
   * The keys are the field names and the values are the field bindings.
   */
  fields: Record<keyof InferOutput<T>, ReturnType<typeof useField>>

  /**
   * A reactive boolean ref that indicates whether the form is valid or not.
   */
  isValid: ComputedRef<boolean>

  /**
   * A reactive boolean ref that indicates whether the form should be eagerly validated or not.
   */
  eagerValid: ComputedRef<boolean>

  /**
   * A function that returns an array of error messages for a specific field.
   * @param fieldName - The name of the field.
   * @returns An array of error messages for the specified field.
   */
  getErrors: (fieldName: keyof InferOutput<T>) => string[]

  /**
   * A function that returns the first error message for a specific field.
   * @param fieldName - The name of the field.
   * @returns The first error message for the specified field.
   */
  getErrorMessage: (fieldName: keyof InferOutput<T>) => string

  /**
   * A function that validates the entire form and returns a boolean indicating whether the form is valid or not.
   * @returns A boolean indicating whether the form is valid or not.
   */
  validate: () => boolean

  /**
   * A function that resets the form to its initial state.
   */
  reset: () => void

  /**
   * A function that clears the errors of a specific field.
   * @param fieldName - The name of the field.
   */
  clearErrors: () => void

  /**
   * A function that validates a specific field and returns a boolean indicating whether the field is valid or not.
   * @param fieldName - The name of the field.
   * @returns A boolean indicating whether the field is valid or not.
   */
  validateField: (fieldName: keyof InferOutput<T>) => boolean

  /**
   * A function that resets a specific field to its initial state.
   * @param fieldName - The name of the field.
   */
  resetField: (fieldName: keyof InferOutput<T>) => void

  /**
   * A function that sets the errors of a specific field.
   * @param fieldName - The name of the field.
   * @param errors - The errors to set.
   */
  setFieldErrors: (fieldName: keyof InferOutput<T>, ...errors: string[]) => void

  /**
   * A function that clears the errors of a specific field.
   * @param fieldName - The name of the field.
   */
  clearFieldErrors: (fieldName: keyof InferOutput<T>) => void

  /**
   * Returns the bindings for a specific field.
   * @param fieldName - The name of the field.
   * @returns The field bindings for the element.
   */
  registerField: (fieldName: keyof InferOutput<T>) => UnwrapRef<UseFieldReturnType<T>["binds"]>
}
